import axiosIns from '@/libs/axios'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchOvertimes(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axiosIns.get('/overtime/list', {params: queryParams}).then(response => resolve(response)).catch(error => reject(error))
            })
        },
        fetchOvertime(ctx, id) {
            return new Promise((resolve, reject) => {
                axiosIns.get(`/overtime/${id}`).then(response => resolve(response)).catch(error => reject(error))
            })
        },
        addOvertime(ctx, data) {
            return new Promise((resolve, reject) => {
                axiosIns.post('/overtime', data).then(response => resolve(response)).catch(error => reject(error))
            })
        },
        updateOvertime(ctx, data) {
            return new Promise((resolve, reject) => {
                axiosIns.put('/overtime', data).then(response => resolve(response)).catch(error => reject(error))
            })
        },
        fetchUsers(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axiosIns.get('/user/list', {params: queryParams}).then(response => resolve(response)).catch(error => reject(error))
            })
        },
        deleteOvertimeRecord(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axiosIns.delete('/overtime', {params: queryParams}).then(response => resolve(response)).catch(error => reject(error))
            })
        },
        exportExcel(ctx, queryParams){
            return new Promise((resolve, reject) => {
              axiosIns
                .get("/overtime/export", { params: queryParams, responseType: 'blob' })
                .then((response) => resolve(response))
                .catch((error) => reject(error));
            });
          }


    }
}
