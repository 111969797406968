<template>
  <div>
    <overtime-add-new
      :is-add-new-overtime-sidebar-active.sync="isAddNewOvertimeSidebarActive"
      :id="id"
      :data="overtimeData"
      @refetch-data="refetchData"
    >
    </overtime-add-new>

    <overtime-list-filter
      :user-filter.sync="userFilter"
      :date-from-filter.sync="dateFromFilter"
      :date-to-filter.sync="dateToFilter"
      :user-options="userOptions"
      :remark-filter.sync="remarkFilter"
    />

    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="搜尋..."
              />
              <b-button variant="primary" @click="handleCreate" v-if="false">
                <span class="text-nowrap">Add OT Record</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refOvertimeListTable"
        class="position-relative"
        :items="fetchOvertimes"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(approve_bit)="data">
          <feather-icon
            v-if="data.item.approve_bit"
            icon="CheckIcon"
            size="18"
            class="mr-50 text-success"
          />
        </template>

        <template #cell(off_work_bit)="data">
        <feather-icon 
        v-if="data.item.off_work_bit"
        icon="CheckIcon"
        size="18"
        class="mr-50 text-success"
        />
    </template>

        <template #cell(work_from)="data">
          <span v-if="!data.item.off_work_bit">{{ data.item.time_from }}</span>
        </template>
        <template #cell(work_to)="data">
          <span v-if="!data.item.off_work_bit">{{ data.item.time_to }}</span>
        </template>
        <template #cell(time_from)="data">
          <span v-if="data.item.off_work_bit">{{ data.item.time_from }}</span>
        </template>
        <template #cell(time_to)="data">
          <span v-if="data.item.off_work_bit">{{ data.item.time_to }}</span>
        </template>

        <template #cell(remark)="data">
          <div v-html="data.item.remark" v-if="remarkFilter"></div>
        </template>

        <template #cell(action)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-if="ability.can('update', 'overtimes')"
              @click="handleEdit(data.item)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="ability.can('delete','overtimes')"
              @click="confirmDelete(data.item.id)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalOvertimes"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import overtimeStoreModule from "@/views/admin/overtime/overtimeStoreModule.js";
import useOvertimesList from "./useOvertimesList";
import OvertimeAddNew from "./OvertimeListAddNew.vue";
import OvertimeListFilter from "./OvertimeListFilter.vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    OvertimeAddNew,
    OvertimeListFilter,
  },
  methods: {
    confirmDelete(id) {
        this.$swal({
          title: "確認刪除?",
          text: "你的操作是最終的，將無法恢復！",
          showCancelButton: true,
          confirmButtonText: "刪除",
          cancelButtonText: '取消',
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            this.$store
              .dispatch("admin-overtime/deleteOvertimeRecord", { id: id })
              .then((response) => {
                this.$swal({
                  text: response.data.message,
                  icon: "success",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  showClass: {
                    popup: "animate__animated animate__bounceIn",
                  },
                  buttonsStyling: false,
                });
                this.refetchData();
              })
              .catch((error) => {
                this.$swal({
                  text: JSON.stringify(error.response.data.message),
                  icon: "error",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  showClass: {
                    popup: "animate__animated animate__bounceIn",
                  },
                  buttonsStyling: false,
                });
              });
          }
        });
      },
    handleCreate() {
      this.id = 0;
      this.resetOvertimeData();
      this.isAddNewOvertimeSidebarActive = true;
    },
    handleEdit(item) {
      console.log(item);
      this.id = item.id;
      this.overtimeData = {...item}
      /*
      this.overtimeData.id = item.id;
      this.overtimeData.title = item.title;
      this.overtimeData.remark = item.remark;
      this.overtimeData.time_from = item.time_from;
      this.overtimeData.time_to = item.time_to;
      this.overtimeData.total_hour = item.total_hour;
      this.overtimeData.approve_bit = item.approve_bit;
      this.overtimeData.admin_remark = item.full_admin_remarkday_bit;
      */
      this.isAddNewOvertimeSidebarActive = true;
    },
    resetOvertimeData() {
      this.overtimeData = {
        id: 0,
        title: "",
        remark: "",
        time_from: "",
        time_to: "",
        //ot_date: '',
        total_hour: 0,
        off_work_bit: false,
      };
    },
  },
  data() {
    return {
      id: 0,
      overtimeData: {},
    };
  },
  setup() {
    const ADMIN_OVERTIME_STORE_MODULE_NAME = "admin-overtime";

    if (!store.hasModule(ADMIN_OVERTIME_STORE_MODULE_NAME))
      store.registerModule(
        ADMIN_OVERTIME_STORE_MODULE_NAME,
        overtimeStoreModule
      );

    onUnmounted(() => {
      if (store.hasModule(ADMIN_OVERTIME_STORE_MODULE_NAME))
        store.unregisterModule(ADMIN_OVERTIME_STORE_MODULE_NAME);
    });

    const isAddNewOvertimeSidebarActive = ref(false);

    const {
      fetchOvertimes,
      tableColumns,
      perPage,
      currentPage,
      totalOvertimes,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refOvertimeListTable,
      refetchData,
      ability,
      userFilter,
      dateFromFilter,
      dateToFilter,
    } = useOvertimesList();

    // Filter
    const userOptions = ref([]);
    const remarkFilter = ref(true);
    store
      .dispatch(ADMIN_OVERTIME_STORE_MODULE_NAME + "/fetchUsers", {
        is_options: true,
      })
      .then((response) => {
        userOptions.value = response.data.users;
      })
      .catch((error) => {
        console.log("Error in fetching user options");
      });

    return {
      //Sidebar
      isAddNewOvertimeSidebarActive,

      fetchOvertimes,
      tableColumns,
      perPage,
      currentPage,
      totalOvertimes,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refOvertimeListTable,
      refetchData,
      avatarText,
      ability,

      //Filter
      userFilter,
      dateFromFilter,
      dateToFilter,
      remarkFilter,
      userOptions,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>