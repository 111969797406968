<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>過濾器</h5>
      
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>User</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="userFilter"
            :options="userOptions"
            label="name"
            class="w-100"
            :reduce="(val) => val.id"
            @input="(val) => $emit('update:userFilter', val)"
          />
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>Date From</label>
          <b-form-datepicker
            id="date_from"
            placeholder="Date From"
            local="en"
            :value="dateFromFilter"
            @input="(val) => $emit('update:dateFromFilter', val)"
            :date-format-options="{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }"
          />
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>Date To</label>
          <b-form-datepicker
            id="date_to"
            placeholder="Date To"
            local="en"
            :value="dateToFilter"
            @input="(val) => $emit('update:dateToFilter', val)"
            :date-format-options="{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }"
          />
        </b-col>
        <b-col cols="12" md="1" class="mb-md-0 mb-2">
          <label></label>
          <div>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              size="sm"
              variant="primary"
              @click="reset"
              style="height: 40px"
            >
              Reset
            </b-button>
          </div>
        </b-col>
        <b-col cols="12" md="2" class="mb-md-0 mb-2">
          <label></label>
        <div class="d-flex align-items-center justify-content-end">
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm"
                :variant="(remarkFilter)?'':'success'"
                @click="triggerRemark()"
                style="height:40px;"
              >
                Hidden Remark
              </b-button>
              </div>
        </b-col>
        <b-col cols="12" md="12" class="mb-md-0 mb-2">
          <label></label>
        <div class="d-flex align-items-center justify-content-end">
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              size="sm"
              variant="success"
              @click="exportExcel"
              style="height: 40px"
              class="mr-1"
            >
              Export to Excel
            </b-button>
              </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BFormDatepicker,
  BButton,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";

export default {
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
    BFormDatepicker,
    BButton,
  },
  methods: {
    exportExcel() {
      this.$swal({
        title: "確認下載?",
        text: "",
        showCancelButton: true,
        confirmButtonText: "下載",
        cancelButtonText: '取消',
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("admin-overtime/exportExcel", {user_id: this.userFilter, date_from: this.dateFromFilter, date_to: this.dateToFilter}).then((response) => {
            const contentDispositionHeader =
              response.headers["content-disposition"];
            let filename ='leave_record'; // 默认文件名，如果无法从头中解析

            if (contentDispositionHeader) {
              const filenameMatch =
                contentDispositionHeader.match(/filename="?(.+)"?/);
              if (filenameMatch && filenameMatch.length > 1) {
                // 这里我们假设服务器返回的文件名是有效的，不需要额外解码
                filename = filenameMatch[1];
              }
            }

            // 处理Blob和创建下载链接
            const blob = new Blob([response.data], {
              type: response.headers["content-type"],
            });
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = downloadUrl;
            link.setAttribute("download", filename); // 使用从头信息中提取的文件名
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(downloadUrl); // 清理blob URL
          });
        }
      });
    },
    reset() {
      this.$emit("update:userFilter", null);
      this.$emit("update:dateFromFilter", null);
      this.$emit("update:dateToFilter", null);
    },
    triggerRemark(){
      this.$emit('update:remarkFilter', !this.remarkFilter)
    }
  },
  props: {
    userFilter: {
      type: [Number, null],
      default: null,
    },
    dateFromFilter: {
      type: [String, null],
      default: null,
    },
    dateToFilter: {
      type: [String, null],
      default: null,
    },
    remarkFilter: {
      type:[Boolean, null],
        default:false,
    },
    userOptions: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
